<template>
  <div class="date-input-wrapper">

    <p v-if="title" class="form-input-title">{{ title }}
      <span v-if="inlineSubtitle" class="inline-subtitle">{{ inlineSubtitle }}</span>
    </p>

    <div class="inline-row">
      <Datepicker
        v-model="value"
        class="form-input-date"
        :clearable="clearable"
        :locale="nl"
        input-format="dd-MM-yyyy"
        weekday-format="EEEEEE"
      >

      </Datepicker>
      <inline-svg
        :src="require('../../../assets/icons/icn_calendar.svg')"
        class="form-icon"
      ></inline-svg>

      <input
        v-if="includeTimeInput"
        v-model="timeInput"
        type="time"
        class="form-input input-time"
        required
      >
    </div>

  </div>
</template>
<script>

import Datepicker from 'vue3-datepicker'
import { ref, watch } from 'vue'
import InlineSvg from 'vue-inline-svg'
import { nl } from 'date-fns/locale'

export default {
  name: 'DateSelector',
  components: {
    Datepicker,
    InlineSvg
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    inlineSubtitle: {
      type: String,
      required: false,
      default: ''
    },
    startDate: {
      type: Date,
      required: false,
      default: undefined
    },
    onChange: {
      type: Function,
      default: () => {
      }
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    includeTimeInput: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const value = ref(props.startDate)

    const timeInput = ref('00:00')

    watch(() => props.startDate, (curr, prev) => {
      if (curr.toString() === prev.toString()) {
        return
      }
      value.value = props.startDate
      if (props.includeTimeInput) {
        let hours = props.startDate.getHours().toString()
        let minutes = props.startDate.getMinutes().toString()
        if (hours.length === 1) hours = '0' + hours
        if (minutes.length === 1) minutes = '0' + minutes
        timeInput.value = `${hours}:${minutes}`
      }
    })

    watch(() => value.value, (curr, prev) => {
      if (curr.toString() === prev.toString()) {
        return
      }
      if (props.onChange) {
        if (props.includeTimeInput) {
          const splitTime = timeInput.value.split(':')
          let hours = splitTime[0]
          let minutes = splitTime[1]

          if (hours.length === 2 && hours[0] === '0') hours = hours[1]
          if (minutes.length === 2 && minutes[0] === '0') minutes = minutes[1]
          const oldDate = value.value
          const newDate = new Date(oldDate)
          newDate.setHours(parseInt(hours))
          newDate.setMinutes(parseInt(minutes))
          // console.log('newDate', newDate)
          value.value = newDate
        }
        props.onChange(value.value)
      }
    })

    if (props.includeTimeInput) {
      watch(() => timeInput.value, (curr, prev) => {
        if (curr.toString() === prev.toString()) {
          return
        }
        // if (props.onChange) {
        const splitTime = curr.split(':')
        let hours = splitTime[0]
        let minutes = splitTime[1]

        if (hours.length === 2 && hours[0] === '0') hours = hours[1]
        if (minutes.length === 2 && minutes[0] === '0') minutes = minutes[1]
        const oldDate = value.value
        const newDate = new Date(oldDate)
        newDate.setHours(parseInt(hours))
        newDate.setMinutes(parseInt(minutes))
        // console.log('newDate', newDate)
        value.value = newDate
      })
    }

    return {
      value,
      nl,

      timeInput,
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.form-input-title {
  margin-bottom: 6px;
}

.input-time {
  border: none;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0.125rem 0.125rem 0 0 rgba(0, 0, 0, 0.1);
  color: var(--blue_dark_01);
  font-size: rem(16);
  height: rem(46);
  padding-left: rem(12);
  padding-right: rem(12);
  font-family: var(--font-primary);
  margin-left: rem(12);
}

:deep {
  .form-input-date {
    border: none;
    border-radius: rem(8);
    background-color: white;
    box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);
    font-size: rem(16);
    height: rem(46);
    padding-left: rem(48);
    padding-right: rem(12);
    cursor:pointer;
    font-family: var(--font-primary);
    color: var(--blue_dark_01);
    width: rem(155);
  }

  .v3dp__datepicker {
    --vdp-bg-color: #ffffff;
    --vdp-text-color: #373b52;
    --vdp-box-shadow: 10px 4px 10px 4px rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81);
    --vdp-border-radius: 8px;
    --vdp-heading-size: 2em;
    --vdp-heading-weight: 600;
    --vdp-heading-hover-color: #eeeeee;
    --vdp-arrow-color: #d8b6b6;
    --vdp-disabled-color: #d5d9e0;
    --vdp-hover-color: #ffffff;
    --vdp-hover-bg-color: #4f5574;
    --vdp-selected-color: #ffffff;
    --vdp-selected-bg-color: #373B52;
    --vdp-elem-border-radius: 0.5em;
    --vdp-divider-color: #ffffff;
    --vdp-elem-font-size: 0.9rem;
  }
}

//.v3dp__popout {
  //padding: rem(20);
//}

.styling-popout {
  background-color: blue;
}

.date-input-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: fit-content;
  align-items: flex-start;
  align-content: center;
  margin: rem(15) 0;
}

.form-title {
  color: #373B52;
  font-weight: 1000;
  margin-bottom: rem(10);
}


.form-icon {
  position: absolute;
  bottom: rem(13);
  left: rem(20);
}

.inline-row {
  display: flex;
}

.input-time {

}

g {
  fill: purple
}

.v3dp__heading__center {
  flex: none !important;
}

.v3dp__heading {
  justify-content: center !important;
}

.v3dp__body {
  font-weight: 500 !important;
}

.v3dp__clearable {
  position: absolute;
  left: rem(450);
  font-size: rem(30);
  top: rem(10)
}

</style>
